import { Plane, MapPin, Hand, Bed, ScanLine, Building, Bot, DollarSign, Map } from 'lucide-react';
import { skillsMap } from './skillsData'; // Import skills lookup

// Import images
import aiPalmReaderImg from '../img/ai-palm-reader.png';
import boardingPassImg from '../img/boardingpass.png';
import extremeDayTripsImg from '../img/extremedaytrips.png';
import findMyBoroughImg from '../img/findmyborough.png';
import fengShuiAIImg from '../img/feng-shui-ai.png';
import strollrImg from '../img/strollr.png';
import ibmImg from '../img/ibm.png';
import taxBotImg from '../img/taxbot.png';
import meetInTheMiddleImg from '../img/meet-in-the-middle.png';

const projectsData = [
  { 
    name: 'Extreme Day Trips', 
    description: 'Website and mobile app to find same day round trip flights (now exited)',
    link: 'https://extremedaytrips.com',
    icon: Plane,
    color: '#FF6B6B',
    image: extremeDayTripsImg,
    skills: ['react', 'nextjs', 'firebase', 'gcp']
  },
  {
    name: 'Meet in the Middle',
    description: 'Multiple origin flight search tool to find the best meeting point for friends from different cities',
    link: 'https://meetinthemiddle.app',
    icon: Map,
    color: '#6B66FF',
    image: meetInTheMiddleImg,
    skills: ['react', 'firebase', 'gcp']
  },
  { 
    name: 'Strollr', 
    description: 'AI Walking Tours - simply type what you\'d like to see and AI will make you a tour of the city!', 
    link: 'https://strollr.xyz',
    icon: MapPin,
    color: '#4ECDC4',
    image: strollrImg,
    skills: ['react', 'nextjs', 'firebase', 'llm']
  },
  { 
    name: 'AI Palm Reader', 
    description: '10k+ Google Play downloads, uses GPT-4 vision to determine your fortune!',
    link: 'https://play.google.com/store/apps/details?id=com.alexchhk.PalmReadingApp&hl=en_GB',
    icon: Hand,
    color: '#FFD93D',
    image: aiPalmReaderImg,
    skills: ['react-native', 'firebase', 'pytorch']
  },
  { 
    name: 'Feng Shui AI', 
    description: 'Helps you organize your room with feng shui principles. Hundreds of downloads on iOS and Android.',
    link: 'https://apps.apple.com/us/app/feng-shui-ai/id6504151323',
    icon: Bed,
    color: '#FF6B6B',
    image: fengShuiAIImg,
    skills: ['react-native', 'firebase', 'tensorflow']
  },
  { 
    name: 'Boarding Pass Scanner', 
    description: 'Native Android app with over 1000 downloads on Google Play written in Kotlin',
    link: 'https://play.google.com/store/apps/details?id=com.alexchoi.boardingpassscanner&hl=en',
    icon: ScanLine,
    color: '#FFFFFF',
    image: boardingPassImg,
    skills: ['kotlin', 'firebase']
  },
  { 
    name: 'FindMyBorough', 
    description: "Master's degree data analytics project to suggest the best borough to live in London",
    link: 'https://github.com/alexechoi/findmyborough',
    icon: Building,
    color: '#4ECDC4',
    image: findMyBoroughImg,
    skills: ['pytorch', 'postgres']
  },
  { 
    name: 'TaxBot', 
    description: 'RAG-based tax adviser on Irish Tax Law',
    link: 'https://taxbot.ie',
    icon: DollarSign,
    color: '#FFD93D',
    image: taxBotImg,
    skills: ['llm', 'firebase', 'postgres']
  },
  { 
    name: 'IBM SkillsBuild Chatbot', 
    description: 'Thesis project comparing LLM with traditional chatbot models',
    link: '#',
    icon: Bot,
    color: '#FF6B6B',
    image: ibmImg,
    skills: ['llm', 'tensorflow']
  }
];

export default projectsData;
