import { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import Skills from './Skills';
import Projects from './Projects';
import FullScreenModal from './FullScreenModal';
import skillsData from './data/skillsData';
import projectsData from './data/projectsData';
import { FaLinkedin, FaGithub, FaXTwitter, FaInstagram } from 'react-icons/fa6';
import './App.css';

function App() {
  const [isSkillsOpen, setIsSkillsOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  const openSkillsModal = () => {
    setIsSkillsOpen(true);
  };

  const closeModal = () => {
    setIsSkillsOpen(false);
    setSelectedProject(null);
  };

  const pages = [
    <div className="grid grid-cols-3 md:grid-cols-5 gap-4">
      <Skills onOpen={openSkillsModal} />
      {projectsData.map((project, index) => (
        <Projects
          key={index}
          project={project}
          onSelectProject={() => setSelectedProject(project)}
        />
      ))}
    </div>
  ];  

  const handleSwipe = (direction) => {
    if (direction === 'LEFT' && currentPage < pages.length - 1) {
      setCurrentPage(currentPage + 1);
    } else if (direction === 'RIGHT' && currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleSwipe('LEFT'),
    onSwipedRight: () => handleSwipe('RIGHT'),
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <div className="animated-gradient-bg min-h-screen p-4 text-white font-sans flex flex-col items-center relative">
      <header className="text-center mb-8">
        <h1 className="text-4xl md:text-5xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-pink-500">
          Alex Choi
        </h1>
        <p className="text-md md:text-lg mt-1">Software Engineering & Data Science</p>
      </header>

      {/* Blob Background */}
      <div className="blob"></div>

      {/* Swipeable App Icons Section */}
      <div {...swipeHandlers} className="w-full flex justify-center mb-16 overflow-hidden">
        <div className="w-full flex justify-center">{pages[currentPage]}</div>
      </div>

      {/* Dock with Social Media Links */}
      <div className="fixed bottom-4 w-full max-w-xs md:max-w-md flex justify-around items-center bg-white bg-opacity-20 rounded-full px-4 py-2 shadow-lg">
        <a href="https://linkedin.com/in/alexchoi1" target="_blank" rel="noopener noreferrer" className="flex flex-col items-center w-16">
          <div className="p-3 rounded-full bg-white bg-opacity-30">
            <FaLinkedin className="text-blue-500 text-2xl" />
          </div>
          <p className="text-xs mt-1 text-center truncate w-full">LinkedIn</p>
        </a>
        <a href="https://github.com/alexechoi" target="_blank" rel="noopener noreferrer" className="flex flex-col items-center w-16">
          <div className="p-3 rounded-full bg-white bg-opacity-30">
            <FaGithub className="text-gray-700 text-2xl" />
          </div>
          <p className="text-xs mt-1 text-center truncate w-full">GitHub</p>
        </a>
        <a href="https://x.com/alexchhk" target="_blank" rel="noopener noreferrer" className="flex flex-col items-center w-16">
          <div className="p-3 rounded-full bg-white bg-opacity-30">
            <FaXTwitter className="text-black-400 text-2xl" />
          </div>
          <p className="text-xs mt-1 text-center truncate w-full">X</p>
        </a>
        <a href="https://instagram.com/alexechoi" target="_blank" rel="noopener noreferrer" className="flex flex-col items-center w-16">
          <div className="p-3 rounded-full bg-white bg-opacity-30">
            <FaInstagram className="text-pink-400 text-2xl" />
          </div>
          <p className="text-xs mt-1 text-center truncate w-full">Instagram</p>
        </a>
      </div>

      {/* FullScreenModal for Skills and Projects */}
      {isSkillsOpen && (
        <FullScreenModal
          title="Skills"
          description="Technical skills include:"
          onClose={closeModal}
        >
          <div className="flex flex-col items-center gap-4 mt-4">
            {Object.entries(skillsData).map(([category, skills]) => (
              <div key={category} className="mb-6 w-full">
                <h4 className="text-xl font-bold text-gray-700 mb-4 text-center">{category}</h4>
                <div className="flex flex-wrap gap-4 justify-center">
                  {skills.map((skill, index) => {
                    const Icon = skill.icon;
                    return (
                      <span
                        key={index}
                        className="px-4 py-2 bg-gradient-to-br from-purple-400 to-blue-500 text-white rounded-lg shadow-md flex items-center gap-2"
                      >
                        <Icon className="text-lg" />
                        {skill.name}
                      </span>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </FullScreenModal>
      )}

      {selectedProject && (
        <FullScreenModal
          title={selectedProject.name}
          description={selectedProject.description}
          image={selectedProject.image}
          link={selectedProject.link}
          skills={selectedProject.skills}
          onClose={closeModal}
        />
      )}
    </div>
  );
}

export default App;