import { useState } from 'react';
import { motion } from 'framer-motion';
import { FaArrowLeft, FaExternalLinkAlt } from 'react-icons/fa';
import { skillsMap } from './data/skillsData';
import { ClipLoader } from 'react-spinners'; // Import spinner

const FullScreenModal = ({ title, description, image, link, skills, onClose, children }) => {
  const [loading, setLoading] = useState(true); // Track image loading state

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      className="fixed inset-0 bg-white z-50 flex flex-col text-black"
    >
      <header className="flex items-center p-4 bg-gray-100 shadow-md">
        <button onClick={onClose} className="text-blue-500 flex items-center space-x-2">
          <FaArrowLeft className="text-xl" />
          <span className="text-lg font-medium">Back</span>
        </button>
      </header>

      <div className="flex flex-col items-center p-6 overflow-auto">
        <h1 className="text-3xl font-bold mb-4 text-center">{title}</h1>
        <p className="text-lg text-center mb-6">{description}</p>

        {/* Image Loader with Spinner */}
        {image && (
          <div className="relative w-full max-w-md">
            {loading && (
              <div className="absolute inset-0 flex items-center justify-center">
                <ClipLoader size={40} color="#3498db" loading={loading} />
              </div>
            )}
            <img
              src={image}
              alt={title}
              className={`w-full h-auto rounded-lg shadow-md mb-4 ${loading ? 'hidden' : 'block'}`}
              onLoad={() => setLoading(false)} // Hide spinner once image loads
              onError={() => setLoading(false)} // Hide spinner if image fails to load
            />
          </div>
        )}

        {link && (
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center space-x-2 px-4 py-2 bg-blue-600 text-white text-lg font-medium rounded-md shadow-md hover:bg-blue-700 transition duration-300 mb-4"
          >
            <span>View Project</span>
            <FaExternalLinkAlt className="text-sm" />
          </a>
        )}

        {/* Render Skills Section */}
        {skills && skills.length > 0 && (
          <div className="mt-4 w-full flex flex-wrap justify-center gap-3">
            {skills.map(skill => {
              const skillData = skillsMap[skill];
              if (!skillData) {
                console.warn(`Skill ID '${skill}' not found in skillsMap`);
                return null;
              }

              return (
                <span key={skill} className="flex items-center px-3 py-1 bg-gray-200 rounded-md text-sm">
                  <skillData.icon className="mr-2 text-lg" /> {skillData.name}
                </span>
              );
            })}
          </div>
        )}

        {children}
      </div>
    </motion.div>
  );
};

export default FullScreenModal;