import { FaReact, FaWordpress, FaJsSquare, FaAndroid, FaDatabase } from 'react-icons/fa';
import { SiNextdotjs, SiKotlin, SiFirebase, SiDocker, SiGooglecloud, SiMongodb, SiPostgresql, SiPytorch, SiTensorflow } from 'react-icons/si';

const skillsData = {
  Frontend: [
    { id: 'react', name: 'React', icon: FaReact },
    { id: 'nextjs', name: 'Next.js', icon: SiNextdotjs },
    { id: 'wordpress', name: 'WordPress', icon: FaWordpress },
    { id: 'react-native', name: 'React Native', icon: FaAndroid },
  ],
  Backend: [
    { id: 'kotlin', name: 'Kotlin', icon: SiKotlin },
    { id: 'firebase', name: 'Firebase', icon: SiFirebase },
    { id: 'gcp', name: 'Google Cloud', icon: SiGooglecloud },
    { id: 'docker', name: 'Docker', icon: SiDocker },
    { id: 'postgres', name: 'Postgres', icon: SiPostgresql },
    { id: 'mongodb', name: 'MongoDB', icon: SiMongodb },
  ],
  AI_ML: [
    { id: 'pytorch', name: 'Pytorch', icon: SiPytorch },
    { id: 'tensorflow', name: 'TensorFlow', icon: SiTensorflow },
    { id: 'llm', name: 'LLM Integration', icon: FaJsSquare },
  ],
};

// 🔹 Create a lookup map for skills
export const skillsMap = Object.values(skillsData)
  .flat()
  .reduce((acc, skill) => ({ ...acc, [skill.id]: skill }), {});

export default skillsData;